import { useEffect, useState } from 'react';
// import logo from './logo.svg';
// import SumsubWebSdk from '@sumsub/websdk-react'
import './App.css';
import { ToastContainer, TypeOptions, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SumsubWebSdk from '@sumsub/websdk-react';
import { EventPayload, MessageHandler, } from '@sumsub/websdk/types/types';

function App() {
  const notify = (message: string, type: TypeOptions = 'error') => toast(message, { type });
  const [accessToken, setAccessToken] = useState(null)
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  // const [user, setUser] = useState<string | null>(null);


  const fetchAccessToken = async () => {
    const urlParams = new URLSearchParams(window.location.search);

    const user = urlParams.get('user');
    if (!user)
      return;
    const BASE_URL = 'https://api.morizonweb.com/api/v1/vault2/';
    const endpoint = `${BASE_URL}public/start-kyc`;

    try {
      const response = await fetch(`${endpoint}?user=${user}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const { data } = await response.json();
      setAccessToken(data?.kyc?.sumSubToken)

      setEmail(`${data?.user?.email}`);
      setPhone(`${data?.user?.phoneNumber}`);

      console.log({ email, phone, data: data.user, uu: `${data?.user?.email}` })

      console.log({ data })
      // setAccessToken(data.accessToken);
      // setError(null);
    } catch (err: any) {
      console.error(err?.response?.data)
      // setAccessToken(null);
      // setError(err.message);
    }
  };

  const verifyAccessToken = async () => {
    const urlParams = new URLSearchParams(window.location.search);

    const user = urlParams.get('user');
    if (!user)
      return;
    const BASE_URL = 'https://api.morizonweb.com/api/v1/vault2/';
    const endpoint = `${BASE_URL}public/verify-kyc`;

    try {
      const response = await fetch(`${endpoint}?user=${user}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const { data } = await response.json();
      console.log({ data })
    } catch (err: any) {
      console.error(err?.response?.data)
      // setAccessToken(null);
      // setError(err.message);
    }
  };

  const accessTokenExpirationHandler = async () => {
    await fetchAccessToken()
    console.log({ accessToken })
    return '';
  }
  const messageHandler: MessageHandler = async (type: any, payload: EventPayload<any>) => {
    console.log({
      type,
      payload
    })

    if (payload.reviewResult?.reviewAnswer) {
      await verifyAccessToken();
    }

  }
  const errorHandler = (error: any) => {
    console.log({ error })
    notify(error.error)

  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    (async () => {

      setEmail(`${urlParams.get('email')}`);
      setPhone(`${urlParams.get('phone')}`);
      // setUser(urlParams.get('user'));

      await fetchAccessToken();
    })();
  }, [])

  return (
    <div className="App">
      {accessToken ? <SumsubWebSdk
        accessToken={accessToken}
        expirationHandler={accessTokenExpirationHandler}
        config={{
          email,
          phone
        }}
        options={{}}
        onMessage={messageHandler}
        onError={errorHandler}
      /> : 'Loading...'}

      <ToastContainer />
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
  );
}

export default App;
